<template>
  <div class="bbs">
    <div class="bbs-main">
      <div class="content">
        <div class="top" v-if="subject.isGlobalTop > 0"></div>
        <a-spin :spinning="loading">
          <h3 class="content-title" v-if="subject.subjectType !== 1">
            <span
              class="qa-ok"
              v-if="subject.subjectType === 2 && subject.isSolved === 1"
              >{{ $t("LB_QA_IsResolved") }}</span
            >
            <!-- 已解决 -->
            <i
              class="condense"
              v-if="subject.subjectType === 3 && subject.isCondensed === 1"
            ></i>
            <span v-html="subject.title"></span>
          </h3>
          <div class="content-head">
            <div class="info">
              <div class="avatar">
                <a-avatar
                  :size="50"
                  :src="require(`@/assets/image/bbs/anonymous.png`)"
                  v-if="subject.isAnonymous === 1"
                />
                <a-avatar
                  :size="50"
                  :src="
                    subject.portrait || require(`@/assets/image/bbs/avatar.png`)
                  "
                  v-else
                >
                  <OpenData type="userName" :openid="subject.createUser" />
                </a-avatar>
              </div>
              <div class="text">
                <div class="name">
                  <span v-if="subject.isAnonymous === 1">{{
                    $t("bbs.anonymous_user")
                  }}</span>
                  <!-- 匿名用户 -->
                  <template v-else>
                    <OpenData type="userName" :openid="subject.createUser" />
                    <div
                      class="comment-medal"
                      v-if="
                        subject.mediaImage && companyInfo.menu.includes('medal')
                      "
                    >
                      <div class="medal-name">
                        <img
                          draggable="false"
                          :src="subject.mediaImage"
                          alt=""
                        />
                        {{ subject.medalName }}
                      </div>
                    </div>
                  </template>
                </div>
                <div class="time">
                  <span>{{ timeFrom(subject.createTime * 1000) }}</span>
                  <span style="padding-left: 20px">
                    {{ $t("bbs.form", [" "]) }}
                    <!-- 来自 -->
                    <span class="a" @click="groupJump">
                      {{ subject.groupName }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="ctrl">
              <div class="ctrl-item" v-if="subject.subjectType === 2">
                <!-- 点击回答 -->
                <a-tooltip :title="$t('bbs.click_answer')">
                  <span class="anticon anticon-message" @click="jumpComment">
                    <svg viewBox="64 64 896 896" width="1em" height="1em">
                      <path
                        d="M853.333333 768c35.413333 0 64-20.650667 64-55.978667V170.581333A63.978667 63.978667 0 0 0 853.333333 106.666667H170.666667C135.253333 106.666667 106.666667 135.253333 106.666667 170.581333v541.44C106.666667 747.285333 135.338667 768 170.666667 768h201.173333l110.016 117.44a42.752 42.752 0 0 0 60.586667 0.042667L651.904 768H853.333333z m-219.029333-42.666667h-6.250667l-115.797333 129.962667c-0.106667 0.106667-116.010667-129.962667-116.010667-129.962667H170.666667c-11.776 0-21.333333-1.621333-21.333334-13.312V170.581333A21.205333 21.205333 0 0 1 170.666667 149.333333h682.666666c11.776 0 21.333333 9.536 21.333334 21.248v541.44c0 11.754667-9.472 13.312-21.333334 13.312H634.304zM341.333333 490.666667a42.666667 42.666667 0 1 0 0-85.333334 42.666667 42.666667 0 0 0 0 85.333334z m170.666667 0a42.666667 42.666667 0 1 0 0-85.333334 42.666667 42.666667 0 0 0 0 85.333334z m170.666667 0a42.666667 42.666667 0 1 0 0-85.333334 42.666667 42.666667 0 0 0 0 85.333334z"
                        fill="#999"
                      ></path>
                    </svg>
                  </span> </a-tooltip
                ><i>{{ commentCount }}</i>
              </div>
              <div class="ctrl-item" v-else>
                <!-- 点击评论 -->
                <a-tooltip :title="$t('bbs.click_comment')">
                  <MessageOutlined @click="jumpComment" /> </a-tooltip
                ><i>{{ commentCount }}</i>
              </div>
              <div class="ctrl-item" v-if="subject.subjectType === 2">
                <a-tooltip
                  :title="
                    subject.isFollow === 1
                      ? $t('bbs.toggle_follow', 2)
                      : $t('bbs.toggle_follow', 1)
                  "
                >
                  <!-- '取消关注' : '关注' -->
                  <HeartOutlined
                    @click="toFollow"
                    :class="{ ed: subject.isFollow === 1 }"
                  />
                </a-tooltip>
              </div>
              <div class="ctrl-item" v-else>
                <a-tooltip
                  :title="
                    subject.isLike === 1
                      ? $t('bbs.toggle_like', 2)
                      : $t('bbs.toggle_like', 1)
                  "
                >
                  <!-- '取消点赞' : '点赞' -->
                  <LikeOutlined
                    @click="toLike"
                    :class="{ ed: subject.isLike === 1 }"
                  /> </a-tooltip
                ><i>{{ subject.likes }}</i>
              </div>
              <div class="ctrl-item">
                <a-tooltip
                  :title="
                    subject.isCollect === 1
                      ? $t('bbs.toggle_collect', 2)
                      : $t('bbs.toggle_collect', 1)
                  "
                >
                  <!-- '取消收藏' : '收藏' -->
                  <StarOutlined
                    @click="toCollect"
                    :class="{ ed: subject.isCollect === 1 }"
                  />
                </a-tooltip>
              </div>
              <template v-if="group.groupId">
                <div
                  class="ctrl-item"
                  v-if="
                    group.masterUid === userInfo.userId ||
                    group.adminUids.includes(userInfo.userId) ||
                    subject.isManage === 1 ||
                    subject.createUid === userInfo.userId
                  "
                >
                  <a-dropdown
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                    placement="bottomRight"
                  >
                    <EllipsisOutlined />
                    <template #overlay>
                      <a-menu>
                        <template
                          v-if="
                            group.masterUid === userInfo.userId ||
                            group.adminUids.includes(userInfo.userId)
                          "
                        >
                          <template v-if="subject.isManage === 1">
                            <a-menu-item
                              v-if="
                                subject.isGlobalTop <= 0 && group.scope1 !== 6
                              "
                              @click="toTop(1)"
                              >{{ $t("bbs.global_top") }}</a-menu-item
                            >
                            <!-- 全局置顶 -->
                            <a-menu-item
                              v-if="subject.isGlobalTop === 1"
                              @click="toTop(2)"
                              >{{ $t("bbs.set_circle_top") }}</a-menu-item
                            >
                            <!-- 改为圈内置顶 -->
                            <a-menu-item
                              v-if="
                                subject.isGlobalTop === 2 && group.scope1 !== 6
                              "
                              @click="toTop(1)"
                              >{{ $t("bbs.set_global_top") }}</a-menu-item
                            >
                            <!-- 改为全局置顶 -->
                          </template>
                          <a-menu-item
                            v-if="subject.isGlobalTop <= 0"
                            @click="toTop(2)"
                            >{{ $t("bbs.circle_top") }}</a-menu-item
                          >
                          <!-- 圈内置顶 -->
                          <a-menu-item
                            v-if="
                              subject.isManage === 1 && subject.isGlobalTop > 0
                            "
                            @click="toTop(0)"
                            >{{ $t("bbs.cancel_top") }}</a-menu-item
                          >
                          <!-- 取消置顶 -->
                          <a-menu-item
                            v-else-if="
                              subject.isManage !== 1 &&
                              subject.isGlobalTop === 2
                            "
                            @click="toTop(0)"
                            >{{ $t("bbs.cancel_top") }}</a-menu-item
                          >
                          <!-- 取消置顶 -->
                          <a-menu-item
                            v-if="
                              subject.isCondensed !== 1 &&
                              subject.subjectType !== 2
                            "
                            @click="toCondensed(1)"
                            >{{ $t("bbs.refined") }}</a-menu-item
                          >
                          <!-- 加精 -->
                          <a-menu-item
                            v-if="subject.isCondensed === 1"
                            @click="toCondensed(0)"
                            >{{ $t("bbs.cancel_refined") }}</a-menu-item
                          >
                          <!-- 取消加精 -->
                        </template>
                        <a-menu-item
                          v-if="
                            subject.createUid === userInfo.userId &&
                            subject.status !== 8
                          "
                          @click="edit(subject.subjectType)"
                          >{{ $t("bbs.edit") }}</a-menu-item
                        >
                        <!-- 编辑 -->
                        <a-menu-item
                          v-if="
                            subject.createUid === userInfo.userId ||
                            group.masterUid === userInfo.userId ||
                            group.adminUids.includes(userInfo.userId)
                          "
                          @click="toDel"
                          >{{ $t("bbs.delete") }}</a-menu-item
                        >
                        <!-- 删除 -->
                        <a-menu-item
                          v-if="
                            subject.subjectType === 2 &&
                            (group.masterUid === userInfo.userId ||
                              group.adminUids.includes(userInfo.userId) ||
                              subject.createUid === userInfo.userId) &&
                            subject.status !== 8
                          "
                          @click="toClose()"
                          >{{ $t("bbs.close_quesition") }}</a-menu-item
                        >
                        <!-- 关闭问题 -->
                      </a-menu>
                    </template>
                  </a-dropdown>
                </div>
              </template>
            </div>
          </div>
          <div
            class="content-integral"
            v-if="subject.subjectType === 2 && subject.rewardIntegral"
          >
            <tag :text="'悬赏回答' + subject.rewardIntegral + '积分'" />
          </div>
          <div class="content-topic">
            <span
              class="a"
              v-for="item in subject.topics || []"
              :key="item.topicId"
              @click="topicJump(item)"
            >
              #{{ item.topicName }}#
            </span>
          </div>
          <div class="content-remind" v-if="subject.remindUsers">
            {{ $t("bbs.invited_2") }}&nbsp;&nbsp;
            <!-- 邀请了 -->
            <div
              class="content-remind-item"
              v-for="(user, index) in subject.remindUsers"
              :key="index"
            >
              <a-avatar
                :size="24"
                :src="user.portrait || require(`@/assets/image/bbs/avatar.png`)"
              >
                <OpenData type="userName" :openid="user.realName" />
              </a-avatar>
              <span style="margin-left: 5px">
                <OpenData type="userName" :openid="user.realName" />
              </span>
            </div>
          </div>
          <div class="content-deatil" v-if="subject.subjectType === 1">
            <i class="condense middle" v-if="subject.isCondensed === 1"></i>
            <span v-html="subject.content"></span>
          </div>
          <div class="content-deatil" v-else-if="subject.subjectType === 2">
            <span v-html="subject.content"></span>
          </div>
          <div class="content-deatil" v-else-if="subject.subjectType === 3">
            <div
              class="vhtml"
              v-viewer.static="{ modal: true, title: false }"
              v-html="subject.content"
            ></div>
          </div>
          <div
            class="content-media"
            v-if="
              (subject.coverImgss || subject.coverVideoss) &&
              subject.subjectType !== 3
            "
            v-viewer="{ modal: true, title: false }"
          >
            <template v-if="subject.coverVideoss">
              <div
                class="content-media-item"
                v-for="(item, index) in subject.coverVideoss"
                :key="index"
              >
                <video
                  :src="
                    (
                      item.filePath.slice(0, item.filePath.lastIndexOf('.')) +
                      '/1/mp4/1_1.mp4'
                    ).replace('/source/', '/')
                  "
                />
                <div
                  class="mask"
                  @click="$refs.previewVideoRef.openModal(item.filePath)"
                  v-if="item.status === 1"
                >
                  <PlayCircleFilled class="play" />
                </div>
                <div class="mask" style="cursor: default" v-else>
                  <span class="status">
                    {{ $t("bbs.video", [fileStatus[item.status]]) }}
                    <!-- 视频 -->
                  </span>
                </div>
              </div>
            </template>
            <template v-if="subject.coverImgss">
              <div
                class="content-media-item"
                v-for="(item, index) in subject.coverImgss"
                :key="index"
              >
                <img :src="item" />
              </div>
            </template>
          </div>
          <div
            class="content-file"
            v-if="subject.links || subject.attachmentList"
          >
            <a
              target="_blank"
              :href="
                subject.links.includes('?')
                  ? `${subject.links}&ddtab=true`
                  : `${subject.links}?ddtab=true`
              "
              class="item link"
              v-if="subject.links"
            >
              <div class="icon">
                <LinkOutlined style="color: #fff; font-size: 20px" />
              </div>
              <div class="info">
                <p>{{ subject.links }}</p>
                <span>
                  {{
                    subject.links.indexOf(currHost) === -1
                      ? $t("bbs.form", [$t("bbs.external_link")])
                      : $t("bbs.form", [$t("bbs.internal_link")])
                  }}
                  <!-- 来自外部链接 : 来自内部链接-->
                </span>
              </div>
            </a>
            <template
              v-for="(item, index) in subject.attachmentList"
              :key="index"
            >
              <!-- zip文件不支持预览 -->
              <a-tooltip
                :title="$t('bbs.zip_not_preview')"
                v-if="
                  item.filePath
                    .substr(item.filePath.lastIndexOf('.'))
                    .toLowerCase() == '.zip'
                "
              >
                <div class="item">
                  <div class="icon">
                    <FilePptOutlined style="color: #fff; font-size: 20px" />
                  </div>
                  <div class="info">
                    <p style="cursor: default">
                      {{ item.fileName + ".zip" }}
                    </p>
                    <a
                      @click="fileDownload(item.sourceFilePath, item.fileName)"
                      v-if="subject.isDown === 1"
                      >{{ $t("bbs.click_download") }}</a
                    >
                    <!-- 点击下载 -->
                  </div>
                </div>
              </a-tooltip>
              <template v-else>
                <a
                  v-if="item.status === 1"
                  class="item"
                  target="_blank"
                  :href="
                    '/preview?ddtab=true&filePath=' +
                    item.filePath.replace('/source/', '/')
                  "
                >
                  <div class="icon">
                    <FilePptOutlined style="color: #fff; font-size: 20px" />
                  </div>
                  <div class="info">
                    <p>
                      {{
                        item.fileName +
                        item.filePath.substr(item.filePath.lastIndexOf("."))
                      }}
                    </p>
                    <a
                      @click.stop.prevent="
                        fileDownload(item.sourceFilePath, item.fileName)
                      "
                      v-if="subject.isDown === 1"
                      >{{ $t("bbs.click_download") }}</a
                    >
                    <!-- 点击下载 -->
                  </div>
                </a>
                <a v-else class="item" href="javascript:;">
                  <div class="icon">
                    <FilePptOutlined style="color: #fff; font-size: 20px" />
                  </div>
                  <div class="info">
                    <p>
                      {{
                        item.fileName +
                        item.filePath.substr(item.filePath.lastIndexOf("."))
                      }}
                    </p>
                    <a href="javascript:;">{{
                      $t("bbs.attachment", [fileStatus[item.status]])
                    }}</a>
                    <!-- 附件 -->
                  </div>
                </a>
              </template>
            </template>
          </div>
        </a-spin>
      </div>
      <div class="comments">
        <span id="commentInput"></span>
        <replyInput
          ref="replyInputRef"
          :userInfo="userInfo"
          :communityConfig="communityConfig"
          :group="group"
          :isEditer="subject.subjectType === 2 && subject.status !== 8"
          type="comment"
          :disabled="subject.status === 8"
          :subjectType="subject.subjectType"
          @toComment="toComment"
        />
        <div class="comments-list">
          <div class="comments-list-head">
            <div class="head-tab">
              <span v-if="subject.subjectType !== 2"
                >{{ $t("bbs.all_comments") }}（{{ commentCount }}）</span
              >
              <!-- 全部评论（ -->
              <template v-else>
                <span
                  :class="['pointer', { ed: commentTab !== 1 }]"
                  @click="commentTabChange(0)"
                  >{{ $t("bbs.all_answers") }}（{{ commentCount }}）</span
                >
                <!-- 全部回答（ -->
                <span
                  :class="['pointer', { ed: commentTab === 1 }]"
                  @click="commentTabChange(1)"
                  >{{ $t("bbs.featured_answer") }}（{{ recommendCount }}）</span
                >
                <!-- 精选回答（ -->
              </template>
            </div>
            <div class="sort">
              <a-dropdown
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                placement="bottomRight"
              >
                <span class="sort-default">
                  {{
                    commentParams.sortKey === "hot"
                      ? $t("bbs.hot_content")
                      : $t("bbs.by_time")
                  }}
                  <!-- "按热度" : "按时间" -->
                  <DownOutlined />
                </span>
                <template #overlay>
                  <a-menu>
                    <a-menu-item @click="sortComment('time')">{{
                      $t("bbs.by_time")
                    }}</a-menu-item>
                    <!-- 按时间 -->
                    <a-menu-item @click="sortComment('hot')">{{
                      $t("bbs.hot_content")
                    }}</a-menu-item>
                    <!-- 按热度 -->
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
          </div>
          <a-spin :spinning="commentLoading">
            <div
              class="comments-list-content"
              v-infinite-scroll="handleInfiniteOnLoad"
              :infinite-scroll-immediate-check="false"
              :infinite-scroll-disabled="scrollDisabled"
              infinite-scroll-watch-disabled="scrollDisabled"
              :infinite-scroll-distance="50"
            >
              <div
                class="comment-item"
                v-for="item in commentList"
                :key="item.commentId"
              >
                <div class="avatar">
                  <a-avatar
                    :size="50"
                    :src="require(`@/assets/image/bbs/anonymous.png`)"
                    v-if="item.isAnonymous === 1"
                  />
                  <a-avatar
                    :size="50"
                    :src="
                      item.createPortrait ||
                      require(`@/assets/image/bbs/avatar.png`)
                    "
                    v-else
                  >
                    <OpenData type="userName" :openid="item.createUser" />
                  </a-avatar>
                </div>
                <div class="info">
                  <div class="name">
                    <div class="name-left">
                      <span v-if="item.isAnonymous === 1">{{
                        $t("bbs.anonymous_user")
                      }}</span>
                      <!-- 匿名用户 -->
                      <template v-else>
                        <OpenData type="userName" :openid="item.createUser" />
                        <div
                          class="comment-medal"
                          v-if="
                            item.mediaImage &&
                            companyInfo.menu.includes('medal')
                          "
                        >
                          <div class="medal-name">
                            <img
                              draggable="false"
                              :src="item.mediaImage"
                              alt=""
                            />
                            {{ item.medalName }}
                          </div>
                        </div>
                      </template>
                      <span class="recommend" v-if="item.isRecommend === 1">
                        {{ $t("bbs.featured_recommended") }}
                        <!-- 精选推荐 -->
                      </span>
                    </div>
                    <div class="solve" v-if="item.isAdopt === 1">
                      {{ $t("bbs.adopted") }}
                    </div>
                    <!-- 被采纳 -->
                  </div>
                  <div
                    class="text vhtml"
                    v-viewer.static="{ modal: true, title: false }"
                    v-html="item.content"
                  ></div>
                  <div
                    class="media"
                    v-viewer.static="{ modal: true, title: false }"
                    v-if="item.imageList"
                  >
                    <div
                      class="media-item"
                      v-for="(item, index) in item.imageList"
                      :key="index"
                    >
                      <img :src="item.filePath" />
                    </div>
                  </div>
                  <div
                    class="remind"
                    v-if="item.remindUserList && item.remindUserList.length"
                  >
                    {{ $t("bbs.mentioned") }}
                    <!-- 提到了 -->
                    <div
                      class="remind-item"
                      v-for="(user, index) in item.remindUserList"
                      :key="index"
                    >
                      <a-avatar
                        :size="24"
                        :src="
                          user.portrait ||
                          require(`@/assets/image/bbs/avatar.png`)
                        "
                      >
                        <OpenData type="userName" :openid="user.realName" />
                      </a-avatar>
                      <span style="margin-left: 5px">
                        <OpenData type="userName" :openid="user.realName" />
                      </span>
                    </div>
                  </div>
                  <div class="foot">
                    <div class="time">
                      <span>{{ timeFrom(item.createTime) }}</span>
                      <!-- "收起" : "查看" -->
                      <span
                        class="pointer"
                        v-if="item.replyList && item.replyList.length"
                        @click="item.replyListShow = !item.replyListShow"
                        >{{
                          item.replyListShow ? $t("CM_Retract") : $t("cm_view")
                        }}{{
                          $t("bbs.nth_replies", [item.replyList.length])
                        }}</span
                      >
                      <!-- 条回复 -->
                    </div>
                    <div class="ctrl" v-if="subject.status !== 8">
                      <div
                        class="ctrl-item"
                        v-if="
                          (item.createUid === userInfo.userId ||
                            group.masterUid === userInfo.userId ||
                            group.adminUids.includes(userInfo.userId)) &&
                          item.isAdopt !== 1
                        "
                        @click="toDelComment(item)"
                      >
                        <DeleteOutlined /><i>{{ $t("bbs.delete") }}</i>
                        <!-- 删除 -->
                      </div>
                      <div
                        class="ctrl-item"
                        @click="toSolve(item)"
                        v-if="
                          subject.subjectType === 2 &&
                          subject.isSolved !== 1 &&
                          subject.createUid !== item.createUid &&
                          subject.createUid === userInfo.userId
                        "
                      >
                        <FileDoneOutlined /><i>{{ $t("bbs.adopt") }}</i>
                        <!-- 采纳 -->
                      </div>
                      <div
                        class="ctrl-item"
                        @click="toRecommend(item)"
                        v-if="
                          subject.subjectType === 2 &&
                          item.isRecommend !== 1 &&
                          (group.masterUid === userInfo.userId ||
                            group.adminUids.includes(userInfo.userId) ||
                            subject.isManage === 1)
                        "
                      >
                        <SendOutlined /><i>{{ $t("bbs.recommend") }}</i>
                        <!-- 推荐 -->
                      </div>
                      <div class="ctrl-item" @click="toReplyShow(item)">
                        <MessageOutlined /><i>{{ $t("CM_Reply") }}</i>
                        <!-- 回复 -->
                      </div>
                      <div class="ctrl-item" @click="toCommentLike(item)">
                        <LikeOutlined :class="{ ed: item.isLike === 1 }" /><i>{{
                          item.isLike === 1
                            ? $t("bbs.liked")
                            : $t("bbs.toggle_like", 1)
                        }}</i>
                        <!-- "已点赞" : "点赞" -->
                      </div>
                    </div>
                  </div>
                  <div style="margin-top: 10px" v-if="item.replyShow">
                    <replyInput
                      :userInfo="userInfo"
                      :communityConfig="communityConfig"
                      :group="group"
                      type="reply"
                      @toComment="toComment"
                    />
                  </div>
                  <div class="reply-list" v-if="item.replyListShow">
                    <div
                      class="reply-item"
                      v-for="(reply, index) in item.replyList"
                      :key="reply.commentId"
                    >
                      <div class="avatar">
                        <a-avatar
                          :size="50"
                          :src="require(`@/assets/image/bbs/anonymous.png`)"
                          v-if="reply.isAnonymous === 1"
                        />
                        <a-avatar
                          :size="50"
                          :src="
                            reply.createPortrait ||
                            require(`@/assets/image/bbs/avatar.png`)
                          "
                          v-else
                        >
                          <OpenData
                            type="userName"
                            :openid="reply.createUser"
                          />
                        </a-avatar>
                      </div>
                      <div class="info">
                        <div class="name">
                          <span v-if="reply.isAnonymous === 1">{{
                            $t("bbs.anonymous_user")
                          }}</span>
                          <!-- 匿名用户 -->
                          <div v-else>
                            <OpenData
                              type="userName"
                              :openid="reply.createUser"
                            />
                            <div
                              class="comment-medal"
                              v-if="
                                reply.mediaImage &&
                                companyInfo.menu.includes('medal')
                              "
                            >
                              <div class="medal-name">
                                <img
                                  draggable="false"
                                  :src="reply.mediaImage"
                                  alt=""
                                />
                                {{ reply.medalName }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <p class="text">
                          {{ $t("bbs.replied") }}
                          <!-- 回复了 -->
                          <span v-if="reply.isToAnonymous === 1">{{
                            $t("bbs.anonymous_user")
                          }}</span>
                          <!-- 匿名用户 -->
                          <span v-else
                            ><OpenData type="userName" :openid="reply.toUser"
                          /></span>
                          {{ reply.content }}
                        </p>
                        <div
                          class="media"
                          v-viewer.static="{ modal: true, title: false }"
                          v-if="reply.imageList"
                        >
                          <div
                            class="media-item"
                            v-for="(replyItem, replyIndex) in reply.imageList"
                            :key="replyIndex"
                          >
                            <img :src="replyItem.filePath" />
                          </div>
                        </div>
                        <div
                          class="remind"
                          v-if="
                            reply.remindUserList && reply.remindUserList.length
                          "
                        >
                          {{ $t("bbs.mentioned") }}
                          <!-- 提到了 -->
                          <div
                            class="remind-item"
                            v-for="(user, userIndex) in reply.remindUserList"
                            :key="userIndex"
                          >
                            <a-avatar
                              :size="24"
                              :src="
                                user.portrait ||
                                require(`@/assets/image/bbs/avatar.png`)
                              "
                            >
                              <OpenData
                                type="userName"
                                :openid="user.realName"
                              />
                            </a-avatar>
                            <span style="margin-left: 5px">
                              <OpenData
                                type="userName"
                                :openid="user.realName"
                              />
                            </span>
                          </div>
                        </div>
                        <div class="foot">
                          <div class="time">
                            <span>{{ timeFrom(reply.createTime) }}</span>
                          </div>
                          <div class="ctrl">
                            <div
                              class="ctrl-item"
                              v-if="
                                item.createUid === userInfo.userId ||
                                group.masterUid === userInfo.userId ||
                                group.adminUids.includes(userInfo.userId)
                              "
                              @click="toDelReply(reply, index, item)"
                            >
                              <DeleteOutlined /><i>{{ $t("bbs.delete") }}</i>
                              <!-- 删除 -->
                            </div>
                            <div
                              class="ctrl-item"
                              @click="toReplyShow(reply, item)"
                            >
                              <MessageOutlined /><i>{{ $t("CM_Reply") }}</i>
                              <!-- 回复 -->
                            </div>
                            <div
                              class="ctrl-item"
                              @click="toCommentLike(reply)"
                            >
                              <LikeOutlined
                                :class="{ ed: reply.isLike === 1 }"
                              /><i>{{
                                reply.isLike === 1
                                  ? $t("bbs.liked")
                                  : $t("bbs.toggle_like", 1)
                              }}</i>
                              <!-- "已点赞" : "点赞" -->
                            </div>
                          </div>
                        </div>
                        <div style="margin-top: 10px" v-if="reply.replyShow">
                          <replyInput
                            :userInfo="userInfo"
                            :communityConfig="communityConfig"
                            :group="group"
                            type="reply2"
                            @toComment="toComment"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a-empty
                v-if="!commentList.length && !commentLoading"
                style="padding: 50px 0"
              />
            </div>
            <div class="load-more" v-if="commentList.length && !commentLoading">
              {{
                scrollDisabled ? $t("bbs.no_more") : $t("LB_Doc_Mobile_Load")
              }}
              <!-- "已经到底了" : "加载中..." -->
            </div>
          </a-spin>
        </div>
      </div>
    </div>
    <div class="bbs-sidebar">
      <!-- 热门话题 -->
      <sideTopic :newWindow="newWindow" :title="$t('bbs.hot_topic')" />
      <sidePosts
        v-if="subject.subjectType !== 2 && subject.subjectId"
        :newWindow="newWindow"
        :subjectType="subject.subjectType"
        :topics="subject.topics"
        :groupId="groupId"
      />
      <div class="bbs-sidebar-box" v-if="group.showMember === 1">
        <sideMembers :groupId="groupId" />
      </div>
    </div>
  </div>
  <modalPosts
    ref="modalPostsRef"
    :communityConfig="communityConfig"
    @handleOk="getSubject()"
  />
  <modalQuestion
    ref="modalQuestionRef"
    :communityConfig="communityConfig"
    @handleOk="getSubject()"
  />
  <modalArticle
    ref="modalArticleRef"
    :communityConfig="communityConfig"
    @handleOk="getSubject()"
  />
  <previewVideo ref="previewVideoRef" />
</template>

<script>
import { useI18n } from "vue-i18n";
import {
  reactive,
  toRefs,
  ref,
  computed,
  createVNode,
  getCurrentInstance,
  nextTick,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

import { debounce } from "lodash";
import { timeFrom, fileDownload } from "@/utils/tools";

import {
  getCommunityConfig,
  getGroupDeatil,
  getSubjectDeatil,
  delSubject,
  followSubject,
  collectSubject,
  closeSubject,
  likeSubject,
  topSubject,
  condensedSubject,
  getCommentList,
  submitComment,
  likeComment,
  delComment,
  solveComment,
  recommendComment,
} from "@/api/bbs";

import { Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";

import tag from "./components/tag.vue";
import sideTopic from "./components/sideTopic.vue";
import sidePosts from "./components/sidePosts.vue";
import sideMembers from "./components/sideMembers.vue";
import modalPosts from "./components/modalPosts.vue";
import modalQuestion from "./components/modalQuestion.vue";
import modalArticle from "./components/modalArticle.vue";
import replyInput from "./components/replyInput.vue";
import previewVideo from "./components/previewVideo.vue";
import OpenData from "@/components/OpenData.vue";

export default {
  components: {
    tag,
    sideTopic,
    sidePosts,
    sideMembers,
    modalPosts,
    modalQuestion,
    modalArticle,
    replyInput,
    previewVideo,
    OpenData,
  },
  setup() {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      userInfo: computed(() => store.getters.userInfo),
      newWindow: computed(() => store.getters.companyInfo.useNewWindow == 1),
      groupId: Number(route.query.groupId || 0),
      subjectId: Number(route.query.subjectId || 0),
      toComment: Number(route.query.toComment || 0),
      loading: false,
      communityConfig: {},
      group: { adminUids: "" },
      subject: {},
      scrollDisabled: true,
      commentLoading: false,
      commentTab: 0,
      commentCount: 0,
      recommendCount: 0,
      commentParams: {
        sortKey: "time",
        sort: 2,
        pageSize: 10,
        page: 1,
        subjectId: Number(route.query.subjectId || 0),
      },
      commentList: [],
      currentComment: {},
      currentReply: {},
    });
    const companyInfo = computed(() => store.getters.companyInfo);

    getCommunityConfig().then((res) => {
      state.communityConfig = res.data;
    });

    getGroupDeatil(state.groupId).then((res) => {
      if (res.ret === 0) {
        state.loading = true;
        state.group = res.data;
        getSubject();
      } else {
        router.replace({ path: "/bbs" });
      }
    });

    const getSubject = () => {
      state.loading = true;
      getSubjectDeatil({
        id: state.subjectId,
      }).then((res) => {
        state.loading = false;
        if (res.ret === 0) {
          state.subject = res.data;
          if (state.toComment === 1) jumpComment();
        } else {
          router.push({
            path: "/bbs/group",
            query: {
              groupId: state.groupId,
            },
          });
        }
      });
    };

    const jumpComment = () => {
      setTimeout(() => {
        document.getElementById("commentInput").scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 0);
    };

    const toLike = debounce(() => {
      likeSubject({ id: state.subjectId }).then((res) => {
        let isLike = state.subject.isLike === 1;
        state.subject.isLike = isLike ? 2 : 1;
        isLike ? state.subject.likes-- : state.subject.likes++;
      });
    }, 500);

    const toFollow = debounce(() => {
      followSubject({ id: state.subjectId }).then((res) => {
        let isFollow = state.subject.isFollow === 1;
        state.subject.isFollow = isFollow ? 2 : 1;
      });
    }, 500);

    const toCollect = debounce(() => {
      collectSubject({ id: state.subjectId }).then((res) => {
        let isCollect = state.subject.isCollect === 1;
        state.subject.isCollect = isCollect ? 2 : 1;
      });
    }, 500);

    const toTop = (value) => {
      topSubject({
        id: state.subjectId,
        isGlobalTop: value,
      }).then((res) => {
        proxy.$message.success($t("CM_Success"));
        // 操作成功
        state.subject.isGlobalTop = value;
      });
    };

    const toCondensed = (value) => {
      condensedSubject({
        id: state.subjectId,
        isCondensed: value,
      }).then((res) => {
        proxy.$message.success($t("CM_Success"));
        // 操作成功
        state.subject.isCondensed = value;
      });
    };

    const toClose = () => {
      closeSubject({
        id: state.subjectId,
      }).then((res) => {
        proxy.$message.success($t("CM_Success"));
        // 操作成功
        state.subject.status = 8;
      });
    };

    const toDel = () => {
      Modal.confirm({
        title: () => $t("bbs.are_you_sure_to_delete"),
        // 确认删除吗？
        icon: () => createVNode(ExclamationCircleOutlined),
        onOk() {
          delSubject({ id: state.subjectId }).then((res) => {
            proxy.$message.success($t("CM_SuccessfullyDelete"));
            // 删除成功
            router.replace({ path: "/bbs" });
          });
        },
      });
    };

    const modalPostsRef = ref(null);
    const modalQuestionRef = ref(null);
    const modalArticleRef = ref(null);
    const edit = (type) => {
      if (type === 1) {
        modalPostsRef.value.openModal(state.subject);
      } else if (type === 2) {
        modalQuestionRef.value.openModal(state.subject);
      } else if (type === 3) {
        modalArticleRef.value.openModal(state.subject);
      }
    };

    const topicJump = (item) => {
      if (state.newWindow) {
        window.open(`/bbs/topic?ddtab=true&topicId=${item.topicId}`);
      } else {
        router.push({
          path: "/bbs/topic",
          query: {
            topicId: item.topicId,
          },
        });
      }
    };

    const groupJump = () => {
      if (state.newWindow) {
        window.open(`/bbs/group?ddtab=true&groupId=${state.groupId}`);
      } else {
        router.push({
          path: "/bbs/group",
          query: {
            groupId: state.groupId,
          },
        });
      }
    };

    const getComment = () => {
      if (state.commentParams.page === 1) {
        state.commentLoading = true;
      }
      getCommentList(state.commentParams).then((res) => {
        state.commentLoading = false;
        state.commentCount = res.data.totals;
        state.recommendCount = res.data.recommendCount;
        let list = res.data.list || [];
        if (state.commentParams.page === 1) {
          state.commentList = list;
        } else {
          state.commentList.push(...list);
        }
        let totals =
          state.commentParams.isRecommend === 1
            ? res.data.recommendCount
            : res.data.totals;
        if (state.commentList.length < totals) {
          nextTick(() => {
            state.scrollDisabled = false;
          });
        } else {
          state.scrollDisabled = true;
        }
      });
    };
    getComment();

    const commentTabChange = (type) => {
      state.commentTab = type;
      state.commentParams.isRecommend = type;
      state.commentParams.page = 1;
      getComment();
    };

    const sortComment = (type) => {
      if (type !== state.commentParams.sortKey) {
        state.commentParams.page = 1;
        state.commentParams.sortKey = type;
        getComment();
      }
    };

    const handleInfiniteOnLoad = () => {
      if (!state.scrollDisabled) {
        state.commentParams.page++;
        getComment();
      }
    };

    const toReplyShow = debounce((item, parent) => {
      if (state.group.isComment === 2) {
        if (state.group.masterUid === state.userInfo.userId) {
        } else {
          proxy.$message.warn($t("bbs.only_owner_can", [$t("CM_Reply")]));
          // 仅圈主可以回复
          return false;
        }
      } else if (state.group.isComment === 3) {
        if (
          state.group.masterUid === state.userInfo.userId ||
          state.group.adminUids.includes(state.userInfo.userId)
        ) {
        } else {
          proxy.$message.warn($t("bbs.only_owner_admin_can", [$t("CM_Reply")]));
          // 仅圈主或管理员可以回复
          return false;
        }
      }
      state.currentComment = parent || item;
      state.currentReply.replyShow = false;
      item.replyShow = true;
      state.currentReply = item;
    }, 500);

    const replyInputRef = ref(null);
    const toComment = ({ type, params }) => {
      params.subjectId = state.subjectId;
      params.subjectType = state.subject.subjectType;
      if (type === "reply") {
        params.parentId = state.currentReply.commentId;
      } else if (type === "reply2") {
        params.parentId = state.currentReply.commentId;
        params.toUid = state.currentReply.createUid;
      }
      submitComment(params).then((res) => {
        if (res.ret === 0) {
          proxy.$message.success($t("CM_Success"));
          // 操作成功
          if (type === "comment") {
            replyInputRef.value.init();
            state.commentParams.page = 1;
            getComment();
          } else {
            state.currentComment.replyShow = false;
            state.currentReply.replyShow = false;
            let newDate = {
              commentId: res.data,
              subjectId: params.subjectId,
              content: params.content,
              createAccount: state.userInfo.account,
              createPortrait: state.userInfo.portrait,
              createTime: Date.parse(new Date()) / 1000,
              createUid: state.userInfo.userId,
              createUser: state.userInfo.realName,
              isAnonymous: params.isAnonymous,
              isLike: 0,
              isToAnonymous: state.currentReply.isAnonymous,
              likes: 0,
              parentId: state.currentReply.parentId,
              subjectType: params.subjectType,
              toUid: state.currentReply.createUid,
              toUser: state.currentReply.createUser,
              remindUids: params.remindUids,
              remindUserList: params.remindMembers,
            };
            state.currentComment.replyList =
              state.currentComment.replyList || [];
            state.currentComment.replyList.push(newDate);
          }
        } else {
          replyInputRef.value.loading = false;
        }
      });
    };

    const toCommentLike = debounce((item) => {
      likeComment({
        subjectId: state.subjectId,
        commentId: item.commentId,
      }).then((res) => {
        if (res.ret === 0) {
          let isLike = item.isLike === 1;
          item.isLike = isLike ? 2 : 1;
        }
      });
    }, 500);

    const toDelComment = (item) => {
      Modal.confirm({
        title: () => $t("bbs.are_you_sure_to_delete"),
        // 确认删除吗？
        icon: () => createVNode(ExclamationCircleOutlined),
        onOk() {
          delComment({
            subjectId: state.subjectId,
            commentId: item.commentId,
          }).then((res) => {
            if (res.ret === 0) {
              proxy.$message.success($t("CM_SuccessfullyDelete"));
              // 删除成功
              state.commentParams.page = 1;
              getComment();
            }
          });
        },
      });
    };

    const toDelReply = (item, index, parent) => {
      Modal.confirm({
        title: () => $t("bbs.are_you_sure_to_delete"),
        // 确认删除吗？
        icon: () => createVNode(ExclamationCircleOutlined),
        onOk() {
          delComment({
            subjectId: state.subjectId,
            commentId: item.commentId,
          }).then((res) => {
            if (res.ret === 0) {
              proxy.$message.success($t("CM_SuccessfullyDelete"));
              // 删除成功
              parent.replyList.splice(index, 1);
              if (parent.replyList.length === 0) {
                parent.replyListShow = false;
              }
            }
          });
        },
      });
    };

    const toSolve = (item) => {
      Modal.confirm({
        title: () => $t("CM_Tip"),
        // 提示
        icon: () => createVNode(ExclamationCircleOutlined),
        content: $t("bbs.after_adpot_cannot_cancel"),
        // 采纳后不可取消，确定要采纳吗？
        onOk() {
          solveComment({
            subjectID: state.subjectId,
            commentID: item.commentId,
          }).then((res) => {
            if (res.ret === 0) {
              proxy.$message.success($t("bbs.adopted_successfully"));
              // 采纳成功
              item.isAdopt = 1;
              state.subject.isSolved = 1;
            }
          });
        },
      });
    };

    const toRecommend = (item) => {
      Modal.confirm({
        title: () => $t("CM_Tip"),
        // 提示
        icon: () => createVNode(ExclamationCircleOutlined),
        content: $t("bbs.after_recommend_cannot_cancel"),
        // 推荐后不可取消，确定要推荐吗？
        onOk() {
          recommendComment({
            subjectID: state.subjectId,
            commentID: item.commentId,
          }).then((res) => {
            if (res.ret === 0) {
              proxy.$message.success($t("bbs.recommended_successfully"));
              // 推荐成功
              state.recommendCount++;
              item.isRecommend = 1;
            }
          });
        },
      });
    };

    return {
      currHost: location.host,
      timeFrom,
      fileDownload,
      ...toRefs(state),
      companyInfo,
      getSubject,
      jumpComment,
      toLike,
      toFollow,
      toCollect,
      toTop,
      toCondensed,
      toClose,
      toDel,
      modalPostsRef,
      modalQuestionRef,
      modalArticleRef,
      edit,
      topicJump,
      groupJump,
      handleInfiniteOnLoad,
      commentTabChange,
      sortComment,
      toReplyShow,
      replyInputRef,
      toComment,
      toCommentLike,
      toDelComment,
      toDelReply,
      toSolve,
      toRecommend,
      fileStatus: {
        10: $t("bbs.file_status_1", 1), // 未转换
        11: $t("bbs.file_status_1", 2), // 待转换
        12: $t("bbs.file_status_2", 1), // 转换中
        13: $t("bbs.file_status_2", 2), // 转换失败
      },
    };
  },
};
</script>

<style lang="less" scoped>
.bbs {
  .mixinWrap();
  .mixinFlex(space-between);
  padding: 20px 0 80px;
  &-main {
    width: 906px;
  }
  &-sidebar {
    width: 270px;
    &-box {
      margin-bottom: 24px;
    }
  }
  .content {
    min-height: 200px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    padding: 30px 30px 12px;
    position: relative;
    .top {
      position: absolute;
      top: -1px;
      left: -1px;
      width: 46px;
      height: 47px;
      background-image: url("~@/assets/image/bbs/top.png");
      background-size: 100% 100%;
    }
    .a {
      cursor: pointer;
      &:hover {
        color: @color-theme!important;
      }
    }
    .condense {
      display: inline-block;
      width: 12px;
      height: 16px;
      background-image: url("~@/assets/image/bbs/solve.png");
      background-size: 100% 100%;
      &.middle {
        vertical-align: middle;
        width: 10px;
        height: 14px;
        margin-right: 4px;
      }
    }
    &-head {
      .mixinFlex(space-between);
      margin-bottom: 16px;
      .info {
        .mixinFlex(flex-start);
        .avatar {
          width: 50px;
        }
        .text {
          padding-left: 14px;
          font-size: 14px;
          .mixinFlex(center; flex-start; column);
          .time {
            color: #666;
            line-height: 22px;
          }
        }
      }
      .ctrl {
        user-select: none;
        .mixinFlex(flex-end; center);
        color: #999;
        font-size: 20px;
        &-item {
          margin-left: 30px;
          .mixinFlex(flex-start; center);
          &:first-child {
            margin-left: 0;
          }
          .anticon {
            cursor: pointer;
          }
          .ed {
            color: @color-theme;
          }
          i {
            font-style: mormal;
            margin-left: 6px;
          }
        }
      }
    }
    &-integral {
      margin-bottom: 16px;
    }
    &-topic {
      margin-bottom: 16px;
      .a {
        font-size: 14px;
        line-height: 22px;
        display: inline-block;
        color: @color-theme;
        margin-right: 12px;
        cursor: pointer;
      }
    }
    &-remind {
      .mixinFlex(flex-start);
      line-height: 26px;
      margin-bottom: 10px;
      flex-wrap: wrap;
      color: #999;
      &-item {
        .mixinFlex(flex-start; center);
        margin-right: 12px;
        margin-bottom: 6px;
        color: #666;
      }
    }
    &-title {
      font-size: 20px;
      line-height: 26px;
      font-weight: normal;
      color: #333;
      margin-bottom: 16px;
      .qa-ok {
        font-size: 20px;
        color: #389e0d;
        padding: 0 8px 0 28px;
        position: relative;
        user-select: none;
        &::before {
          content: "";
          width: 24px;
          height: 24px;
          position: absolute;
          top: 2px;
          left: 0;
          background-image: url("~@/assets/image/bbs/qa_ok.png");
          background-size: 24px 24px;
        }
      }
    }
    &-deatil {
      font-size: 14px;
      line-height: 22px;
      color: #333;
      margin-bottom: 16px;
      word-break: break-all;
      white-space: pre-line;
    }
    &-media {
      margin-bottom: 6px;
      &-item {
        width: 260px;
        height: 150px;
        display: inline-block;
        margin-right: 24px;
        margin-bottom: 12px;
        background-color: #f5f5f5;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        &:nth-child(3n) {
          margin-right: 0;
        }
        video,
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .mask {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background-color: rgba(0, 0, 0, 0.1);
          .mixinFlex(center; center);
          .play {
            font-size: 45px;
            color: #fff;
          }
          .status {
            font-size: 15px;
            color: #f00;
            letter-spacing: 1px;
            user-select: none;
          }
        }
      }
    }
    &-file {
      margin-bottom: 16px;
      display: flex;
      flex-wrap: wrap;
      .item {
        padding: 10px 14px;
        background-color: #fafafa;
        border-radius: 4px;
        margin-bottom: 8px;
        min-width: 49%;
        .mixinFlex(space-between);
        &.link {
          cursor: pointer;
        }
        &:first-of-type {
          flex-grow: 1;
        }
        &:nth-child(2n) {
          margin-left: 2%;
        }
        .icon {
          width: 40px;
          height: 40px;
          background-color: var(--theme);
          border-radius: 2px;
          .mixinFlex(center; center);
        }
        .info {
          width: calc(100% - 50px);
          .mixinFlex(center; flex-start; column);
          font-size: 12px;
          p {
            width: 304px;
            color: #333;
            .mixinEllipsis(20px);
            margin: 0;
            cursor: pointer;
          }
          span {
            color: #999;
            cursor: pointer;
          }
        }
      }
    }
  }
  .comments {
    margin-top: 24px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    padding: 30px;
    &-list {
      margin-top: 60px;
      &-head {
        .mixinFlex(space-between; center);
        border-bottom: 1px solid #d9d9d9;
        padding-bottom: 10px;
        color: #333333;
        line-height: 28px;
        .head-tab {
          .mixinFlex(flex-start; center);
          span {
            font-size: 20px;
            font-weight: normal;
            margin-bottom: 0;
            margin-right: 30px;
            &.pointer {
              cursor: pointer;
            }
            &.ed {
              color: @color-theme;
            }
          }
        }
        .sort {
          font-size: 14px;
          text-align: right;
          &-default {
            cursor: default;
            user-select: none;
          }
        }
      }
      &-content {
        min-height: 200px;
        .comment-item {
          .mixinFlex(space-between);
          padding: 30px 0;
          border-bottom: 1px solid #d9d9d9;
          .avatar {
            width: 50px;
          }
          .info {
            width: calc(100% - 64px);
            .name {
              font-size: 14px;
              line-height: 22px;
              color: #202020;
              margin-bottom: 6px;
              .mixinFlex(space-between);
              &-left {
                .mixinFlex(flex-start; center);
                .solve {
                  margin-left: 16px;
                }
              }
              .solve {
                font-size: 14px;
                line-height: 22px;
                color: #fa541c;
                padding: 0 0 0 18px;
                position: relative;
                user-select: none;
                &::before {
                  content: "";
                  width: 12px;
                  height: 16px;
                  position: absolute;
                  top: 3px;
                  left: 0;
                  background-image: url("~@/assets/image/bbs/solve.png");
                  background-size: 12px 16px;
                }
              }
              .recommend {
                margin-left: 16px;
                font-size: 14px;
                line-height: 22px;
                color: #3cd0cb;
                padding: 0 0 0 18px;
                position: relative;
                user-select: none;
                &::before {
                  content: "";
                  width: 12px;
                  height: 16px;
                  position: absolute;
                  top: 3px;
                  left: 0;
                  background-image: url("~@/assets/image/bbs/icon-recommend.png");
                  background-size: 12px 16px;
                }
              }
            }
            .text {
              font-size: 14px;
              line-height: 22px;
              color: #666;
              word-break: break-all;
              margin-bottom: 10px;
              span {
                color: #202020;
              }
            }
            .media {
              &-item {
                width: 182px;
                height: 105px;
                display: inline-block;
                margin-right: 17px;
                margin-bottom: 12px;
                background-color: #f5f5f5;
                vertical-align: middle;
                cursor: pointer;
                &:nth-child(4n) {
                  margin-right: 0;
                }
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
            }
            .remind {
              .mixinFlex(flex-start);
              line-height: 26px;
              margin-bottom: 10px;
              color: #999;
              &-item {
                .mixinFlex(flex-start; center);
                margin-left: 12px;
                color: #666;
              }
            }
            .foot {
              .mixinFlex(space-between; center);
              .time {
                color: #7a859b;
                line-height: 22px;
                span {
                  padding-right: 20px;
                }
                .pointer {
                  cursor: pointer;
                  user-select: none;
                }
              }
              .ctrl {
                user-select: none;
                .mixinFlex(flex-end; center);
                line-height: 22px;
                &-item {
                  margin-left: 20px;
                  .mixinFlex(flex-start; center);
                  color: #7a859b;
                  cursor: pointer;
                  .ed {
                    color: @color-theme;
                  }
                  i {
                    font-style: mormal;
                    margin-left: 5px;
                  }
                }
              }
            }
            .reply-list {
              margin-top: 10px;
              background-color: #fafafa;
              padding: 10px 20px;
              .reply-item {
                .mixinFlex(space-between);
                padding: 15px 0;
                border-bottom: 1px solid #f1f1f1;
                &:last-child {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }
    }
    .load-more {
      text-align: center;
      font-size: 14px;
      color: #999;
      line-height: 20px;
      padding: 24px 0 0;
    }
  }
  ::v-deep(.vhtml) {
    i {
      font-style: italic;
    }
    table {
      border-top: 1px solid #ccc;
      border-left: 1px solid #ccc;
    }
    th {
      border-bottom: 2px solid #ccc;
      text-align: center;
      background-color: #f1f1f1;
      border-right: 1px solid #ccc;
      padding: 3px 5px;
      min-height: 30px;
      height: 30px;
    }
    td {
      border-bottom: 1px solid #ccc;
      border-right: 1px solid #ccc;
      padding: 3px 5px;
      min-height: 30px;
      height: 30px;
    }
    blockquote {
      display: block;
      border-left: 8px solid #d0e5f2;
      padding: 5px 10px;
      margin: 10px 0;
      line-height: 1.4;
      font-size: 100%;
      background-color: #f1f1f1;
      p {
        margin-bottom: 0;
      }
    }
    a {
      color: #2056f6;
    }
  }
}
.comment-medal {
  padding-left: 13px;
  margin-left: 5px;
  .mixinFlex(flex-start, center);
  display: inline-flex;
  .medal-name {
    display: inline-block;
    min-width: 52px;
    height: 16px;
    background: linear-gradient(
      90deg,
      rgba(252, 106, 43, 1) 0%,
      rgba(222, 205, 58, 1) 100%
    );
    font-size: 12px;
    line-height: 16px;
    color: #fff;
    padding: 0 8px 0 14px;
    border-radius: 0 8px 8px 0;
    position: relative;
    img {
      width: 24px;
      height: 24px;
      position: absolute;
      left: -13px;
      top: -4px;
    }
  }
}
</style>
